import React, { useState } from "react"
import { graphql } from "gatsby"
import SEO from "../helpers/seo"
import Helmet from "react-helmet"
import Img from "gatsby-image"

export default function About({ data }) {
  const {
    seoTitle,
    seoKeywords,
    headline,
    whoWeAreTitle,
    whoWeAreDescriptions,
    team,
    client,
  } = data.contentfulAboutPage
  const [hover, setHover] = useState(false)
  const [selectedBgColor, setSelectedBgColor] = useState(null)

  const mouseOut = () => {
    setHover(false)
  }

  const createMarkup = content => {
    return { __html: content }
  }

  return (
    <React.Fragment>
      <Helmet>
        <body
          className={hover ? "about-page header-transparent" : "about-page"}
        />
      </Helmet>
      <SEO title={seoTitle} keywords={seoKeywords} />
      <div className="content-wrapper">
        <section className="intro">
          <div
            className="intro-title"
            dangerouslySetInnerHTML={createMarkup(
              headline.content[0].content[0].value
            )}
          />
          <div className="service">
            <div className="title">{whoWeAreTitle}</div>
            <p>{whoWeAreDescriptions.whoWeAreDescriptions}</p>
          </div>
        </section>
        <section className="the-team">
          <div className="title">THE TEAM</div>
          <div className="team-wrapper">
            {team.map(node => (
              <div className="team-profile" key={node.id}>
                <Img
                  fluid={node.image.fluid}
                  alt={node.image.title}
                  key={node.image.fluid.src}
                />
                <div className="team-name">{node.name}</div>
                <div className="team-position">{node.title}</div>
                <a
                  href={"mailto:" + node.email}
                  className="team-contact hover-underline"
                >
                  {node.email}
                </a>
              </div>
            ))}
          </div>
        </section>
        <div
          className="bg-color"
          style={
            hover
              ? { backgroundColor: selectedBgColor, opacity: 1 }
              : { opacity: 0 }
          }
        ></div>
        <section className="clients">
          <div className="title">OUR CLIENTS</div>
          <div className="clients">
            {client.map(node => (
              <div
                className="client"
                key={node.id}
                onMouseOver={() => {
                  setHover(true)
                  setSelectedBgColor(node.backgroundColor)
                }}
                onMouseOut={mouseOut}
              >
                <div className="client-name">{node.clientName}</div>
                <Img
                  fixed={node.clientImageLogo.fixed}
                  alt={node.clientImageLogo.title}
                  style={{ display: "unset" }}
                />
              </div>
            ))}
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

export const myQuery = graphql`
  query MyQuery {
    contentfulAboutPage {
      team {
        email
        name
        id
        title
        image {
          title
          fluid(maxWidth: 520, maxHeight: 690) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      whoWeAreTitle
      whoWeAreDescriptions {
        whoWeAreDescriptions
      }
      headline {
        content {
          content {
            value
          }
        }
      }
      sectionTitle
      seoTitle
      seoKeywords
      client {
        backgroundColor
        clientName
        id
        clientImageLogo {
          title
          fixed(width: 200) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
  }
`
